import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TermsOfUse = () => (
  <Layout>
    <SEO title="Terms of Use" />
    <div className="terms-of-use">
      <div className="container">
        <h1>Terms of Use.</h1>
        <h2>TERMS &amp; CONDITIONS</h2>
        <p>
          <strong>A. General</strong>
        </p>
        <p>
          1. These Standard Terms &amp; Conditions (the "Terms") are being
          displayed on electronic platforms, including, inter alia,
          ‘www.letsmovecity.com’ (the “Website”) and/or ‘mobile applications’
          (the “Apps” and collectively with the Website as the “ Platforms”),
          which Platforms have been developed, and are owned and operated by
          Lets Move Technologies (the “Move”).
        </p>

        <p>
          2. The following two entities (collectively the “Users”) have
          independently read and understood the Terms, and undertake to be
          legally bound by them, in the circumstances enumerated herein:-
        </p>
        <p>
          (a) An individual who is independently registered with the Platform as
          a ‘Passenger’ and ‘User’ of the Platforms (the “ Passenger(s)”). After
          interfacing with an ‘Operator’ (as defined hereunder) through the
          Platforms, the Passenger books an entire vehicle owned by the Operator
          [which the Operator has registered with and operates through the
          Platforms, (the “Vehicle(s)”)] and specifies specific seat(s) (as
          revised by the Passenger, if at all) thereon (the “ Reserved Seats”),
          for traveling along a one-way course of travel that the Operator
          offers to ply with her Vehicle, within a defined geographical area and
          which is made available on the Platforms by the Operators for such
          temporary period as approved by the Operators and reflected through
          the Platforms (the “Operator Course”). The Passenger may travel to and
          from and/or between any set of locations that are available across the
          entire Operator Course, flagged as such by the Operator and reflected
          on the Platforms (“Passenger Point(s)”). The Passenger commences her
          travel by boarding the Vehicle at a specific Passenger Point available
          and flagged on the Operator Course, which she intimates to the
          Operator, and, if applicable, may revise to any other Passenger Point
          available on the Operator Course prior to the Vehicle completing the
          said Operator Course (the “Boarding Point”). She completes her travel
          by disembarking the Vehicle at a specific Passenger Point available
          and flagged on the Operator Course, which she intimates to the
          Operator, and, if applicable, may revise to any other Passenger Point
          available on the Operator Course prior to the Vehicle completing the
          said Operator Course (the “Disembarking Point” and collectively with
          the Boarding Point as the Passenger Points).
        </p>

        <p>
          Location Information: When you use the Services, we collect precise
          location data about the trip from the Move app used by the Driver. If
          you permit the Move app to access location services through the
          permission system used by your mobile operating system (“Platform”),
          we may also collect the precise location of your device when the app
          is running in the foreground or background. We may also derive your
          approximate location from your IP address.
        </p>

        <p>
          (b) an individual who is independently registered with the Platforms
          as an ‘Operator’ and User of the Platforms, and is, on account of
          being authorized therefor at law, responsible for the registration and
          operation of the Vehicles on and through the Platform, in accordance
          with these Terms and other applicable terms (the “Operator(s)”). The
          Operator enables Passengers to book the whole Vehicle for the journey
          of an Operator Course and travel between any and all Passenger Points
          on the said Operator Course (the “Passenger Journey”) on Reserved
          Seats in the said Vehicle, which is driven and plied by the relevant
          Operator’s employee (the “Drivers”), in accordance with these Terms
          and other applicable terms.
        </p>
        <p>3. The Users acknowledge, understand and agree that: -</p>
        <p>(a) Move is not a party to these Terms.</p>
        <p>
          Transaction Information: We collect transaction details related to
          your use of our Services, including the type of service requested,
          date and time the service was provided, amount charged, distance
          traveled, and other related transaction details, duly agreed by you at
          the time of placing your booking. Additionally, if someone uses your
          promo code, we may associate your name with that person.
        </p>
        <p>
          (b) by displaying these Terms on the Platforms and/or otherwise, Move
          does not, as a matter of fact, nor intends to assume or claim any
          right, interest, responsibility under, of and/or for these Terms,
          which do not, in fact or law, nor are intended to legally bind, impact
          or effect Move or its operations.
        </p>
        <p>
          (c) nothing in these Terms is intended to nor does, in fact and in
          law, confer on either of the Users (independently or collectively),
          any right to claim and/or institute any proceedings against Move, in
          any circumstances or seek to impose any liability on and/or demand
          payment of any kind, from Move.
        </p>
        <p>
          (d) Move shall not be or made to be held liable for any indirect,
          incidental, special, exemplary, punitive or consequential damages,
          including lost profits, lost data, personal injury or property damage
          related to, in connection with, or otherwise resulting from any use of
          the Platforms or for any other reasons that may be associated with
          these Terms.
        </p>
        <p>
          (e) additionally, Move shall not be liable for any indirect,
          incidental, special, exemplary, punitive or consequential damages,
          including lost profits, lost data, personal injury or property
          damages, liability or losses arising out of: (i) the Passenger’s use
          of or reliance on the Platforms or her inability to access or use the
          Platforms; or (ii) any transaction or relationship between the
          Passengers and any third party provider necessary for the Passenger
          Journey; or (iii) or for any other reasons that may be associated with
          these Terms.
        </p>
        <p>
          (f) Move does not provide any form of transportation service or
          function as a transportation service provider, and that all such
          transportations services are provided independently by the Operators
          who are not employed by Move, nor operate under the control thereof or
          of any of its affiliates.
        </p>
        <p>
          (g) they shall jointly and/or individually, as the case may be,
          indemnify Move, its owners, licensees, affiliates, group companies and
          their respective officers, directors, agents, and employees, on
          demand, against any claim, action, damage, loss, liability, cost,
          charge, expense or payment which it may pay, suffer, incur or are
          liable for, in relation to any act arising or consequence flowing from
          these Terms, or any violation of any law, rules or regulations
          applicable thereto.
        </p>

        <p>
          4. These Terms may be changed, modified and/or amended in writing,
          only if and to the extent that such change, modification and/or
          amendment is reflected herein;
        </p>
        <p>
          5. The Users acknowledge, understand and agree that prior to reading
          and reviewing these Terms: -
        </p>
        <p>
          (a) The Operator has temporarily offered, for booking and travel by
          any Passengers, the whole of her Vehicle (the “Relevant Vehicle”) that
          she may operate or cause to be operated through and plied by the
          Driver (the “Relevant Driver”) on a particular Operator Course (the “
          Relevant Travel Course”) through the Platform and the seats available
          on the Relevant Vehicle for occupation by the Passenger (the “
          Available Seats”), (the “Operators Terms”).
        </p>
        <p>
          (b) The Passengers have reviewed the particulars of the Operators
          Terms through the Platforms, and voluntarily shared with the said
          relevant Operator (the “Relevant Operator”) all information necessary
          for her Passenger Journey, including her personal information as a
          registered User of the Platforms (the “Relevant Passengers User
          Particulars”) and other information, including inter alia, particulars
          of the seat(s) that she intends to occupy from amongst the Available
          Seats (the “ Relevant Seat”) on the Relevant Vehicle that is offered
          by the Operator to be plied on the Relevant Travel Course between the
          a specific Boarding Point convenient for the Passenger (the “ Relevant
          Boarding Point”) and the available Disembarking Point convenient for
          the Passenger (the “Relevant Disembarking Point” and collectively with
          the Relevant Boarding Point as the “ Relevant Passenger Points”) that
          are both located on the Relevant Operator Course (collectively with
          the Relevant Seat, Relevant Vehicle and Relevant Passenger Points as
          the “Relevant Reservation Particulars ”).
        </p>

        <p>
          (c) The Relevant Operator has also intimated to the Passenger through
          the Platforms, availability of the Relevant Seat for the Passengers
          occupation, the approximate time when the Relevant Operator expects
          the Relevant Vehicle to arrive at the Relevant Boarding Point for the
          Passenger to board the Relevant Vehicle (the “Relevant Boarding
          Time”), the approximate time at which the Operator expects the
          Relevant Vehicle to reach the Relevant Disembarking Point for the
          Passenger to disembark the Relevant Vehicle (the “Relevant
          Disembarking Time” and collectively with the Relevant Boarding Time as
          the “Relevant Times”), the approximate duration of her Passenger
          Journey (the “Relevant Journey Duration”) and the particulars of the
          Relevant Vehicle’s Driver (the “Relevant Driver Details”) and the
          Relevant Vehicle (the “ Relevant Vehicle Details”).
        </p>

        <p>
          (d) The Users have exchanged and processed (as applicable) the
          afore-stated particulars/information amongst themselves voluntarily
          and consensually, and the Passenger is willing to book the whole
          Relevant Vehicle across the Relevant Operator Course and perform her
          Passenger Journey between the Relevant Passenger Points (as may be
          revised) in terms thereof, and subject to these Terms (the “Relevant
          Passenger Journey”).
        </p>

        <p>
          <strong>B. Applicability of the Terms</strong>
        </p>
        <p>
          6. Upon pressing of the ‘Book Now’ button (below) the Users shall have
          accepted to be legally bound by these Terms (the “Acceptance”).
        </p>
        <p>
          Provided that the provision to accept these Terms in terms of the
          Acceptance, shall cease to be available to the Passenger, fifteen (15)
          minutes prior to the Relevant Boarding Time.
        </p>
        <p>
          Personalize and improve the Services, including to provide or
          recommend features, content, social connections, referrals, and
          advertisements.
        </p>
        <p>
          7. The Users unequivocally understand, agree and represent that: -
        </p>

        <p>
          (a) these Terms are part of a common offer that the Relevant Operator
          has made to all Passengers registered with the Platforms.
        </p>
        <p>
          (b) by their respective and sequenced Acceptances, these Passengers
          execute and become party to a single agreement with Relevant Operator,
          to use the whole Relevant Vehicle for the entire distance of the
          Relevant Operator Course and to stop at such Passenger Points
          requested by the said Passengers and as revised by them, that are
          available on the Relevant Operator Course (the “Carriage Contract”).
        </p>
        <p>
          (c) upon Acceptance, all Passengers accept and agree with each other
          and the Relevant Operator, inter alia: -
        </p>
        <p>
          (i) To allow Passengers not party to the Carriage Contract, to become
          Party thereto on Terms similar to those applicable to the Passenger,
          such that and till such time that the entire set of seats available in
          the Relevant Vehicle are occupied and remain occupied till conclusion
          of the Relevant Operator Course;
        </p>
        <p>
          (ii) To travel on the Relevant Vehicle with such other Passengers who
          have, along with the Passenger, become Party to the Carriage Contract,
          and have booked seats on the Relevant Vehicle other than the Relevant
          Seats for their respective occupancy (the “Co-Passengers”);
        </p>
        <p>
          (iii) All Passengers, including the Passenger and the Co-Passengers
          who are or may subsequently become party to the Carriage Contract
          (collectively the “Contracting Passengers”) convey, subject to these
          Terms, their unconditional consent to have the Relevant Operator
          unilaterally revise and/or change the Relevant Operator Course,
          Relevant Passenger Points, Relevant Times and the Relevant Driver, or
          such similar matters relating to the Co-Passengers at any point and as
          she considers appropriate.
        </p>
        <p>
          (iv) Subject to these terms, to allow Passengers who have been issued
          a Seat Reservation Acknowledgement (as defined hereunder), to revise
          the Relevant Seat(s) she intends to occupy, to such other seat(s) that
          become available for occupancy in the Relevant Vehicle, on priority.
        </p>
        <p>
          (v) Subject to these terms, to allow Passengers to change and revise
          their respective Passenger Points to other Passenger Points available
          on the Relevant Operator Course, that the Relevant Vehicle has not yet
          passed, on priority; and
        </p>
        <p>
          (d) the Carriage Contract shall come to an end upon conclusion of the
          Relevant Operator Course by the Relevant Vehicle.
        </p>
        <p>
          (e) upon Acceptance, all Contracting Passengers, shall have entered
          into and accepted to be bound by the Carriage Contract, and agreed to
          travel with other all present and subsequent Co-Passengers in
          accordance with these Terms.
        </p>
        <p>
          8. These Terms shall exhaustively and exclusively regulate the
          relationship of and between the Users commencing upon the Acceptance
          till the Passenger disembarks the Relevant Vehicle at the Relevant
          Disembarking Point, and do not relate to nor have any binding effect
          on Move.
        </p>

        <p>
          9. The Users understand and agree that these Terms are intended to and
          shall also exclusively and exhaustively govern and regulate all
          rights, liabilities, claims, expenses, loss, damage and/or grievances,
          irrespective of its nature, of either User, which arise from, relate
          to or are associated with the Relevant Passenger Journey commencing
          from the Acceptance till conclusion thereof at the Relevant
          Disembarking Point.
        </p>

        <p>
          <strong>C. Seat Reservation & Cancellation</strong>
        </p>

        <p>
          10. Upon Acceptance, the Passenger shall have electronically booked
          for her occupancy and the occupancy of minors (in the capacity of
          their legal guardian) (the “Minors”), the Relevant Seat(s) in the
          Relevant Vehicle, for the duration of the Relevant Passenger Journey
          at the Relevant Times, commencing upon boarding of the Relevant
          Vehicle at the Relevant Boarding Point till disembarking therefrom at
          the Relevant Disembarking Point (the “Seat Reservation”).
        </p>
        <p>
          11. The Seat Reservation shall only be effective if and when the
          Relevant Operator acknowledges and intimates the same to the
          Passenger, through the Platforms (the “Seat Reservation
          Acknowledgement”).
        </p>
        <p>
          12. The Seat Reservation and Seat Reservation Acknowledgement may be
          carried out by the Users in such form and manner, as manner as made
          available by the Relevant Operator on the interface of the Platforms
          and mutually agreed and reflected in these Terms.
        </p>

        <p>13. Subsequent to the receipt of the Seat Reservation Request: -</p>
        <p>
          (a) the Relevant Operator shall, on priority, inform the Passenger in
          receipt of her Seat Reservation Acknowledgement, about such seats that
          subsequently become available for occupancy on the Relevant Vehicle,
          if any; and
        </p>
        <p>
          (b) the Passenger may revise her Seat Reservation to such seat as is
          or subsequently becomes available, for self-occupancy or the occupancy
          of Minors on the Relevant Vehicle.
        </p>
        <p>
          Provided that the Passenger shall be informed of such seats and be
          entitled to make such revisions not later than (03) hours prior the
          Relevant Boarding Time.
        </p>
        <p>
          14. Subject to the rights and obligations embodied in these Terms and
          upon issuance of and pursuant to the Seat Reservation Acknowledgement,
          the Users agree that: -
        </p>
        <p>
          (a) The Relevant Operator shall immediately hold the Relevant Seat(s)
          for the occupancy of the Passenger and/or Minors, as applicable;
        </p>
        <p>
          (b) The Relevant Operator shall exclusively permit the Passenger
          and/or Minors to occupy and travel on the Relevant Seat(s) for the
          duration of the Relevant Passenger Journey till conclusion thereof
          when the Passenger disembarks the Relevant Vehicle at the Relevant
          Disembarking Point;
        </p>
        <p>
          (c) At the time of boarding the Relevant Vehicle, the Passenger shall
          only be entitled to carry on her person, such inanimate objects that
          are legally permissible to possess and carry on the Relevant Vehicles,
          can be easily stored by the Passenger under the Relevant Seat(s), that
          do not, in the opinion of the Relevant Driver, inconvenience the
          Co-Passengers and is acceptable to the Relevant Driver (the
          “Carry-ons”).
        </p>
        <p>
          Provided that in no circumstances shall the Carry-ons include or be
          construed to mean any animal, insect, or person, whether dead or
          alive.
        </p>
        <p>
          Provided further that the Relevant Operator and/or Relevant Driver may
          have the Carry-ons removed from the Relevant Vehicle if, in their sole
          opinion, they appear to be unattended, suspicious or dangerous;
        </p>
        <p>
          (d) After boarding the Relevant Vehicle, the Passenger shall not act
          in any manner that is disrespectful of any Passengers, respecting the
          Seat Reservations of Co Passengers, is not in good faith. Any sort of
          verbal or physical harassment shall not be tolerated.
        </p>
        <p>
          (e) After boarding the Relevant Vehicle, the Passenger shall not act
          in any manner that is contrary or violative of the law; and
        </p>
        <p>
          (Cl. 14(a) and 14(e), shall collectively be referred to as the “ Seat
          Reservation Entitlements”)
        </p>

        <p>
          15. Subject to the rights and obligations embodied in these Terms and
          notwithstanding the issuance of the Seat Reservation Acknowledgement,
          the Passenger may cancel her Seat Reservation at any time prior to
          boarding the Relevant Vehicle (the “Voluntary Seat Cancellation”). In
          this regard, the Parties further agree that: -
        </p>

        <p>
          (a) The Passenger shall bear no liability where she carries out
          Voluntary Seat Cancellation two (2) hours prior to the Relevant
          Boarding time; and
        </p>
        <p>
          (b) Voluntary Seat Cancellation intimated later than two (2) hours
          prior to the Relevant Boarding Time, shall not affect or compromise
          such rights of the Relevant Operator, as would have already accrued in
          favor thereof from the Passenger, upon Acceptance, including, inter
          alia, her right to the Due Sum (as defined hereunder).
        </p>

        <p>
          16. The Users agree that at any time after the Acceptance and prior to
          when the Passenger disembarks the Relevant Vehicle at the Relevant
          Disembarking Point, the Passenger’s Seat Reservation shall be
          automatically cancelled, all Seat Reservation Entitlement shall become
          void and the Passenger shall not be allowed to board the Relevant
          Vehicle and/or be removed or caused to be removed from the Relevant
          Vehicle, as the case may be (the “Automatic Reservation
          Cancellation”), in the following circumstances:-
        </p>

        <p>
          (a) Where the Passenger does not board the Relevant Vehicle at the
          Relevant Boarding Time;
        </p>
        <p>
          (b) Where the Passenger demands entitlements over and above those
          permitted in terms of the Seat Reservation Entitlements and, pursuant
          thereto, the Relevant Operator and/or Relevant Driver disallows, in
          its sole discretion, the Passenger from boarding the Relevant Vehicle
          and/or removes the same therefrom;
        </p>
        <p>
          (c) Where the Passenger fails to fulfill her obligations embodied in
          these Terms;
        </p>
        <p>
          (d) Where, in the sole opinion of the Relevant Operator and/or the
          Relevant Driver, the Passenger is a demonstrable danger to herself,
          her Co-Passengers and other individuals within the vicinity of the
          Relevant Vehicle;
        </p>
        <p>
          (e) Where, in the sole opinion of the Relevant Operator and/or the
          Relevant Driver, the Passenger is a demonstrable source of annoyance
          to her Co-Passengers and other individuals within the vicinity of the
          Relevant Vehicle;
        </p>
        <p>
          (f) Where the Passenger acts in any manner that is contrary to or is,
          in the opinion of the Relevant Operator and/or Relevant Driver, likely
          to contravene the law;
        </p>
        <p>
          (g) Where the Passenger attempts to destroy, vandalize or damage
          and/or cause to be destroyed, vandalized or damaged, any part of the
          Relevant Vehicle at any time;
        </p>
        <p>
          (h) Where, in the sole opinion of the Relevant Operator, the Passenger
          violates these Terms;
        </p>
        <p>
          (i) Where the Passenger, while physically occupying the Relevant Seat
          inside the Relevant Vehicle: -
        </p>
        <p>
          (i) engages in smoking, of any kind, including electronic cigarettes;
        </p>
        <p>
          (ii) consumes any food, drinks or intoxicants, that are prohibited
          under the applicable law or hazardous to the health and welfare of the
          Co-Passengers;
        </p>
        <p>
          (iii) possesses and/or carries any compressed gasses including, inter
          alia, refrigerated, flammable, non-flammable, poisonous such as butane
          oxygen, liquid nitrogen, and compressed gas cylinders;
        </p>
        <p>
          (iv) possesses and/or carries any explosives, munitions, fireworks and
          flares, ammunition including blank cartridges handguns, fireworks, and
          pistol caps; and
        </p>
        <p>
          (v) possesses and/or carries any objects that can or may be used,
          intentionally or otherwise, to cause harm and/or damage to the
          Co-Passengers and any part of the Relevant Vehicle, including sharp
          objects like knives, weapons, and/or tools.
        </p>
        <p>
          Provided that the Automatic Reservation Cancellation shall not affect
          or compromise such rights of the Relevant Operator, as would have
          already accrued in favor thereof from the Passenger, upon Acceptance,
          including, inter alia, her right to the Due Sum (as defined
          hereunder).
        </p>
        <p>
          17. Notwithstanding anything contained in these Terms and particularly
          the issuance of the Seat Reservation Acknowledgement, the Relevant
          Operator reserves the right to unilaterally cancel the Relevant Seat
          Reservation and the Relevant Passenger Journey on the Relevant
          Vehicle, without cause and at any time prior to the Passenger boarding
          the Relevant Vehicle at the Relevant Boarding Point (the “Operators
          Pre-Boarding Cancellation”).
        </p>

        <p>
          <strong>D. The Passenger Journey</strong>
        </p>

        <p>
          18. Subject to these Terms, the Relevant Operator shall make best
          efforts to ensure that the Relevant Vehicle reaches the Relevant
          Boarding Point at the Relevant Boarding Time. The Users however
          acknowledge, understand and agree that: -
        </p>

        <p>
          (a) the precise time specified to the Passenger as the Relevant
          Boarding Time at the time of Acceptance (as and if updated
          subsequently), is intended, understood and agreed as being a mere
          approximation of the Relevant Operator, which is premised on numerous
          assumptions and factors that are beyond the control thereof or may
          constantly change till the Relevant Vehicle actually reaches the
          Relevant Boarding Point. Consequently, the Relevant Boarding Time may
          change as and when the assumptions and factors upon which it is based,
          change, and may continue to change till the Relevant Vehicle actually
          reaches the Relevant Boarding Point and the Passenger boards the
          Relevant Vehicle at the Relevant Boarding Point;
        </p>
        <p>
          (b) under these Terms, the Passenger does not enjoy any right to board
          the Relevant Vehicle precisely at the Relevant Boarding Time; and
        </p>
        <p>
          (c) any variance in and/or difference between the Relevant Boarding
          Time and the actual time when the Passenger actually boards the
          Relevant Vehicle at the Relevant Boarding Point, shall not nor is
          therefore intended to create any basis or right in favor of the
          Passenger to bring any action or claim against the Relevant Operator,
          Relevant Driver or Move.
        </p>
        <p>
          19. Subject to these Terms, after waiting for the Passenger to board
          the Relevant Vehicle for one (01) minute after the Relevant Vehicle’s
          arrival at the Relevant Boarding Point, the Relevant Operator and/or
          Relevant Driver may, pursuant to and as a consequence of Automatic
          Reservation Cancellation, continue plying the Relevant Vehicle on the
          Relevant Operator Course without the Passenger.
        </p>
        <p>
          20. Subject to these Terms, the Relevant Operator and/or Relevant
          Driver may, pursuant to and as a consequence of Automatic Reservation
          Cancellation, disallow the Passenger from boarding the Relevant
          Vehicle at the Relevant Boarding.
        </p>
        <p>
          21. Subject to these Terms, after the Passenger has boarded the
          Relevant Vehicle at the Relevant Boarding Point, the Relevant Operator
          shall make best efforts to ensure that the Relevant Passenger Journey
          is completed within the Relevant Journey Duration. The Users however,
          acknowledge, understand and agree that: -
        </p>
        <p>
          (a) the precise time specified to the Passenger as the Relevant
          Journey Duration at the time of Acceptance (as and if updated
          subsequently), is intended, understood and agreed as being a mere
          approximation of the Relevant Operator, which is premised on numerous
          assumptions and factors that are beyond the control thereof or may
          constantly change till the Relevant Vehicle actually completes the
          Relevant Passenger Journey. Consequently, the Relevant Journey
          Duration may change as the assumptions and factors upon which it is
          based, change, and may continue to change till the Relevant Passenger
          Journey is actually completed;
        </p>
        <p>
          (b) under these Terms, the Passenger does not enjoy any right to carry
          out the Relevant Passenger Journey precisely within the Relevant
          Journey Duration; and
        </p>
        <p>
          (c) any variance in and/or difference between the Relevant Journey
          Duration and the actual time it takes for the Passenger to complete
          her Relevant Passenger Journey, shall not nor is therefore intended to
          create any basis or right in favor of the Passenger to bring any
          action or claim against the Relevant Operator, Relevant Driver or
          Move.
        </p>
        <p>
          22. Subject to these Terms, the Relevant Operator and/or Relevant
          Driver may, pursuant to and as a consequence of Automatic Reservation
          Cancellation, remove the Passenger from the Relevant Vehicle, after
          she has boarded the same at the Relevant Boarding Point.
        </p>
        <p>
          23. Notwithstanding anything contained in these Terms, the Relevant
          Operator reserves the right to unilaterally cancel the Relevant Seat
          Reservation and the Relevant Passenger Journey on the Relevant
          Vehicle, and remove the Passenger from the Relevant Vehicle, without
          cause and at any time prior to the Relevant Vehicle’s arrival at the
          Relevant Disembarking Point (the “Operator Post-Boarding
          Cancellation”).
        </p>
        <p>
          Provided that in case of Operator Post-Boarding Cancellation, the
          Relevant Operator shall arrange or cause to be arranged an alternative
          Vehicle, for the Passenger to complete her Relevant Passenger Journey,
          without any charge or cost to the Passenger, which shall be the sole
          and exclusive compensation that the Passenger shall be entitled to
          claim and which she acknowledges as being adequate and sufficient
          compensation that would be due thereto.
        </p>
        <p>
          24. Subject to these Terms, the Relevant Operator shall make best
          efforts to ensure that the Vehicle shall reach the Relevant
          Disembarking Point at the Relevant Disembarking Time. The Users
          however, acknowledge, understand and agree that: -
        </p>
        <p>
          (a) the precise time specified to the Passenger as the Relevant
          Disembarking Time at the time of Acceptance (as and if updated
          subsequently) is intended, understood and agreed as being a mere
          approximation of the Relevant Operator, which is premised on numerous
          assumptions and factors that are beyond the control thereof or may
          constantly change till the Relevant Vehicle actually arrives at the
          Relevant Disembarking Point. Consequently, the Relevant Disembarking
          Time may change as and if the assumptions and factors, upon which it
          is based, change, and may continue to change till the Relevant Vehicle
          actually reaches the Relevant Disembarking Point and the Passenger
          actually disembarks the Relevant Vehicle at the Relevant Disembarking
          Point.
        </p>
        <p>
          (b) under these Terms, the Passenger does not enjoy any right to
          disembark the Relevant Vehicle at the Relevant Disembarking Point
          precisely at the Relevant Boarding Time.
        </p>
        <p>
          (c) any variance in and/or difference between the Relevant
          Disembarking Time and the actual time when the Passenger actually
          disembarks the Relevant Vehicle at the Relevant Disembarking Point,
          shall not nor is intended to therefore, create any basis for the
          Passenger to bring any action against the Operator, Driver or Move.
        </p>
        <p>
          25. The Passenger shall share her comments, feedback, testimonials and
          reviews on or about the Relevant Operator and the Relevant Passenger
          Journey, through the Platforms(“Feedback”). The Passenger acknowledges
          and permits that the information provided in her Feedback, including
          the Relevant Passenger User Particulars, Relevant Registration
          Particulars and particulars of the Relevant Passenger Journey, may be
          shared with third parties and/or used as the Relevant Operator and/or
          such third parties find appropriate.
        </p>
        <p>
          26. Subsequent to the receipt of the Seat Reservation Request and one
          (01) hour before the Relevant Vehicle reaches the Relevant Passenger
          Points, the Passenger may revise the Relevant Passenger Points to such
          other Passenger Points available on the Relevant Operators Course and
          flagged as such by the Operator.
        </p>

        <p>
          <strong>E. Payment</strong>
        </p>

        <p>
          27. Upon Acceptance, the Passenger shall be obliged to make and the
          Relevant Operator shall be entitled to receive payment of the amounts
          shown on the interface of the Platforms, as consideration for and in
          consideration of the Passenger entering into the Carriage Contract
          (the “ Due Sums”).
        </p>
        <p>
          28. In respect of the Due Sums, the Users unequivocally understand and
          agree to the following: -
        </p>
        <p>
          (a) The Relevant Operator shall acquire a legal right to be paid and
          the Passenger shall become legally obliged to make payment of the Due
          Sums upon and at the time of Acceptance by the Passenger.
        </p>
        <p>(b) The Due Sums are final.</p>
        <p>
          (c) The Passenger shall, both as a matter of fact and law, make
          payment of all applicable taxes, charges, fees and duties etc. that
          are payable in respect of the Due Sum. The Relevant Operator shall not
          be responsible and/or liable for the Passenger’s default in respect of
          the above.
        </p>
        <p>
          (d) At the time of Acceptance, the Passenger shall specify, from
          amongst the available options, the precise electronic mode through
          which it intends to make payment of the Due Sums (the “Preferred
          Payment Mode”).
        </p>
        <p>
          (e) Subsequent to the Acceptance but prior to the issuance of the Seat
          Reservation Acknowledgement, the Relevant Operator shall have the Due
          Sum realized, through and in terms of the Preferred Payment Mode (the
          “ Payment Request”).
        </p>
        <p>
          (f) Where the Preferred Payment Mode is non-responsive to the Payment
          Request and/or the Payment Request is denied, the Relevant Operator
          may request the Passenger to opt for any other electronic mode of
          payment available on the Platforms. Subsequent thereto, the Passenger
          may specify, the precise mode through which it intends to make payment
          of the Due Sums, which shall not, in any event, be the Preferred
          Payment Mode (the “ Secondary Payment Mode”).
        </p>
        <p>
          (g) Subsequent to the Passenger’s intimation of the Secondary Payment
          Mode, the Relevant Operator shall have the Due Sum realized, through
          and in terms of the Secondary Payment Mode (the “Secondary Payment
          Request ”).
        </p>
        <p>
          Provided that the Relevant Operator shall only request the Passenger
          to opt for a Secondary Payment Mode if there are no less than twenty
          nine (29) minutes left till the Relevant Boarding Time.
        </p>
        <p>
          (h) Where the Secondary Payment Mode is non-responsive to the
          Secondary Payment Request and/or the Secondary Payment Request is
          denied, the Relevant Operator shall have the same intimated to the
          Passenger through the Platforms. In such scenario the said Passenger
          shall not become party to the Carriage Contract, not be entitled to
          any benefits thereunder and not be issued the Seat Reservation
          Acknowledgement.
        </p>
        <p>
          (i) The Relevant Operator shall only have the Seat Reservation
          Acknowledgement issued through the Platforms, upon receipt of an
          acknowledgement that either the Preferred Payment Mode and/or
          Secondary Payment Mode, as the case may be, is responsive to the
          Payment Request and/or Secondary Payment Request, as the case may be.
        </p>
        <p>
          (j) Where the it is subsequently learned that the Relevant Operator
          could not have the Due Sum realized from the Preferred Payment Mode
          and/or Secondary Payment Mode, as the case may be, the Due Sum shall
          be recorded, through the Platforms, as an outstanding amount due from
          the Passenger to the Relevant Operator (the “Outstanding Due Sum”).
          The Relevant Operator shall exclude the Passenger from receiving any
          and/or all offers it makes through the Platforms, till it makes
          payment of the Outstanding Due Sum and/or such sums similar thereto
          that the Passenger is required to pay Operators other than the
          Relevant Operators.
        </p>
        <p>
          (k) Any amount realized by the Relevant Operator, that is subsequently
          determined to be in excess of what was due thereto, shall be duly
          recorded through the Platforms, and credited in favor of the
          Passenger, which she can subsequently use through the Platforms for
          travel with the Relevant Operator.
        </p>

        <p>
          <strong>F. Force Majeure, Breach and Damages</strong>
        </p>

        <p>
          29. If the Relevant Operator is unable to comply with or violates any
          of her obligations embodied in these Terms on account of being
          affected by “force majeure” (which means any circumstances beyond the
          reasonable control of that Party, including, without limitation, any
          act of God, riot, strike, mob violence, lock-out or other industrial
          action), she shall not be deemed to be in breach of these Terms or
          otherwise be liable to the Passenger for any delay in performance or
          non-performance of any obligations under these Terms, to the extent
          that such delay or non-performance is due to force majeure, and the
          time for performance of that obligation shall be extended accordingly.
        </p>
        <p>
          30. Subject to these Terms, the Passenger understands, acknowledges,
          undertakes and warrants that pursuant to these Terms or otherwise, she
          shall not be entitled to any right, interest, entitlement, objection
          or claim against the Relevant Operator or Relevant Driver, in respect
          of seeking any breach, indemnity, damages, loss of business or profit
          or revenue, loss of time, loss of opportunity, mental agony,
          suffering, commercial or reputational loss, penalty or any monetary or
          non-monetary claim of any kind whatsoever, direct, indirect,
          incidental, consequential, referred to by any nomenclature, in the
          following circumstances: -
        </p>
        <p>
          (a) Where the Relevant Vehicle is delayed or fails to arrive at the
          Relevant Boarding Point at or around the Relevant Boarding Time; or
        </p>
        <p>
          (b) Where the Relevant Vehicle fails to complete the Relevant
          Passenger Journey within the Relevant Journey Duration.
        </p>
        <p>
          (c) Where the Relevant Vehicle is delayed or fails to arrive at the
          Relevant Disembarking Point at or around the Relevant Disembarking
          Time;
        </p>
        <p>
          (d) Where the Relevant Operator retracts the offer to have the
          Passenger travel on the Relevant Operator Course on the Relevant
          Vehicle, prior to the Acceptance;
        </p>
        <p>
          (e) Where the Relevant Operator revises and/or changes Relevant
          Operator Course, Relevant Passenger Points, Relevant Times and the
          Relevant Driver, at any time; or
        </p>
        <p>
          (f) Where the Passenger claims to have lost any item, belonging or
          luggage in the Vehicle during the Ride.
        </p>
        <p>
          31. In any event, any and all damages payable by one User to the other
          shall not exceed the amount of the Due Sum.
        </p>

        <p>
          <strong>F. Representations, warranties and undertakings</strong>
        </p>

        <p>32. The Relevant Operator represents that: -</p>
        <p>(a) She is duly registered as a User of the Platforms.</p>
        <p>
          (b) She is legally entitled to offer these Terms on the Platforms and
          execute the Carriage Contract;
        </p>
        <p>
          (c) She shall fulfill and perform her obligations arising under these
          Terms, in letter and spirit.
        </p>
        <p>
          (d) The Relevant Driver is an adult who is duly licensed by the
          competent authorities to drive the Relevant Vehicle, and who has been
          cleared a background check that the Relevant Operator has had
          conducted by licensed third parties against the information shared by
          the Relevant Driver;
        </p>
        <p>
          (e) The Relevant Vehicle has been granted the requisite
          approvals/licenses/permits to be plied on the Relevant Travel Courses,
          through the Platforms;
        </p>

        <p>33. The Passenger represents that: -</p>
        <p>(a) She is duly registered as a User with the Platforms.</p>
        <p>
          (b) She is legally entitled to accept these Terms on the Platforms and
          execute the Carriage Contract;
        </p>
        <p>
          (c) She shall fulfill and perform her obligations arising under these
          Terms, in letter and spirit.
        </p>
        <p>
          (d) She shall be exclusively responsible for the Carry-ons, and shall
          not hold the Relevant Operator responsible for any loss and damage to
          and theft of the same.
        </p>
        <p>
          (e) She shall remain seated peacefully on the Relevant Seat, maintain
          proper decorum, respect privacy of her Co-Passengers, and not
          stand/walk in the aisle of the Relevant Vehicle, unless and until it
          reaches the Relevant Disembarking Point.
        </p>
        <p>(f) She shall be dressed appropriate and/or in decent clothes.</p>
        <p>
          (g) She shall not create or cause to be created any nuisance,
          disturbance (whether by words spoken or written or by gestures and
          moves) for the Relevant Driver and/or Co-Passengers, such as making
          unnecessary noises, or speaking, singing or playing music aloud, or
          disturbing co-passengers, or making derogatory, defamatory, hateful,
          ethnic, religious, racial, sexist, controversial, threatening,
          indecent or provocative remarks, or stalking, or hurting, or
          harassing, or eave-teasing, or taking photographs of Co-Passengers, or
          playing/displaying obscene content on electronic mobile devices, or
          smoking, eating or drinking or spraying offensive odors etc.
        </p>
        <p>
          (h) She shall not sell any items while physically present on the
          Relevant Vehicle.
        </p>
        <p>(i) comply with all the applicable provisions of these Terms.</p>

        <p>
          <strong>G. Dispute Resolution</strong>
        </p>

        <p>
          34. The Users agree that any dispute, difference of opinion or claim
          arising out of or relating to the Carriage Contract, including these
          Terms, or breach or the validity thereof (the “Dispute”), shall
          firstly be attempted to be settled amicably by the Users. However if
          the Dispute remains unresolved for a period of thirty (30) days, the
          same shall be referred to arbitration under a panel of three
          arbitrators. Each User shall be entitled to elect an arbitrator and
          the elected arbitrators’ shall then elect an umpire. The arbitration
          proceedings shall be made in accordance with the Arbitration Act,
          1940. The seat of arbitration shall be Karachi and the language of
          arbitration shall be English.
        </p>

        <p>
          <strong>H. Miscellaneous</strong>
        </p>

        <p>
          35. If any provisions of these Terms are determined to be invalid by
          any competent forum having competent jurisdiction, the invalidity of
          such provision shall not affect the validity of the remaining
          provisions of these Terms, which shall remain in full force and
          effect. No waiver of any term of these Terms shall be deemed a further
          or continuing waiver of such term or any other term.
        </p>

        <p>
          36. These Terms shall be governed by and interpreted and construed in
          accordance with the laws of Pakistan.
        </p>

        <p>
          37. These Terms constitute the entire understanding between the Users
          and supersedes all previous oral and written representations,
          agreements or arrangements between them, if any.
        </p>
        <p>
          38. Waiver by any User of any breach of these Terms by the other User,
          shall only be made in writing. No Waiver shall not be deemed a waiver
          of any prior or subsequent breach hereof.
        </p>

        <p>2020 Lets Move Technologies Pvt. Ltd.</p>
      </div>
    </div>
  </Layout>
)

export default TermsOfUse
